<template>
  <div id="rooof-account-list">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1 class="title">
        Rooof Accounts
      </h1>
      <div>
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="medium"
          @click="goToAccountAdd()"
        >
          Add Rooof Account
        </el-button>
        <el-button
          :loading="loading"
          icon="el-icon-download"
          size="medium"
          @click="exportData()"
        >
          Export
        </el-button>
      </div>
    </el-row>

    <rooof-account-table @filter-update="updateFilters" />

    <data-export-dialog
      :show="showExportDialog"
      :data="data"
      data-type="accounts"
      @close="showExportDialog = false"
    />
  </div>
</template>

<script>
import RooofAccountTable from './_components/RooofAccountTable'
import DataExportDialog from '@/components/dialogs/DataExportDialog'
import RooofAccountAPI from '@/services/api/accounts'

export default {
  name: 'RooofAccountList',
  components: {
    'rooof-account-table': RooofAccountTable,
    'data-export-dialog': DataExportDialog
  },
  data () {
    return {
      filters: {},
      data: [],
      loading: false,
      showExportDialog: false
    }
  },
  methods: {
    /**
     * Keeps track of what filters are currently set
     * to use for exporting data.
     *
     * @param {Object} filters
     */
    updateFilters (filters) {
      this.filters = JSON.parse(JSON.stringify(filters))
      delete this.filters.page
      delete this.filters.page_size
    },
    /**
     * Fetch all rooof accounts from the api, then display
     * the export dialog.
     */
    async exportData () {
      this.loading = true
      try {
        const response = await RooofAccountAPI.users.list(this.filters)
        this.data = response.map(account => {
          delete account.postengine_groups
          delete account.invoicing_groups
          return account
        })
        this.loading = false
        this.showExportDialog = true
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Navigates user to the RooofAccountAdd view.
     */
    goToAccountAdd () {
      this.$router.push({ name: 'RooofAccountAdd' })
    }
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font-size: 2rem;
}
</style>
