<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="Filter Rooof Accounts"
    width="450px"
  >
    <el-form inline>
      <el-form-item label="Account Status">
        <el-select
          :value="filters.is_active"
          clearable
          @change="handleChange('is_active', $event)"
        >
          <el-option label="Active" :value="true" />
          <el-option label="Inactive" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Associated Company">
        <el-input
          :value="filters.company"
          @input="handleChange('company', $event)"
        />
      </el-form-item>

      <el-form-item label="Associated Property">
        <el-input
          :value="filters.property"
          @input="handleChange('property', $event)"
        />
      </el-form-item>

      <el-form-item label="Is Rooof Staff">
        <el-select
          :value="filters.is_staff"
          clearable
          @change="handleChange('is_staff', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Is Admin">
        <el-select
          :value="filters.is_superuser"
          clearable
          @change="handleChange('is_superuser', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Is Poster">
        <el-select
          :value="filters.is_poster"
          clearable
          @change="handleChange('is_poster', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Is Corporate">
        <el-select
          :value="filters.is_corporate"
          clearable
          @change="handleChange('is_corporate', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Is an Emergency Contact">
        <el-select
          :value="filters.emergency_contact"
          clearable
          @change="handleChange('emergency_contact', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>

      <el-form-item label="Is Decision Maker">
        <el-select
          :value="filters.decision_maker"
          clearable
          @change="handleChange('decision_maker', $event)"
        >
          <el-option label="Yes" :value="true" />
          <el-option label="No" :value="false" />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="$emit('submit')"
      >
        Set Filters
        <span v-if="filterCount">({{ filterCount }})</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'RooofAccountFiltersDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    filterCount: {
      type: Number,
      required: true
    }
  },
  methods: {
    /**
     * onChange handler for filter options.
     *
     * @param {String} filter - target filter
     * @param {String} value - selected value
     */
    handleChange (filter, value) {
      this.$emit('update-filter', filter, value)
    }
  }
}
</script>
