<template>
  <div id="rooof-account-table">
    <async-paged-table
      ref="table"
      :data="data"
      :total="total"
      :loading="loading"
      :table-attrs="{ 'height': 'calc(100vh - 305px)' }"
      @fetch="fetchData"
    >
      <template #controls>
        <el-button
          size="small"
          class="filters-btn"
          @click="showFiltersDialog = true"
        >
          Filters
        </el-button>
        <el-button
          v-if="filterCount"
          type="text"
          @click="resetFilters"
        >
          Clear All Filters ({{ filterCount }})
        </el-button>
        <filters-dialog
          :show="showFiltersDialog"
          :filters="filters"
          :filter-count="filterCount"
          @update-filter="handleFilterUpdate"
          @submit="handleApplyFilters"
          @close="showFiltersDialog = false"
        />
      </template>
      <el-table-column
        label="Name"
        min-width="250"
        sortable
      >
        <template slot-scope="scope">
          <link-button
            :to="{ name: 'RooofAccountEdit', params: { id: scope.row.id } }"
            type="primary"
          >
            {{ scope.row.name }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Email"
        width="300"
        sortable
      >
        <template slot-scope="scope">
          <link-button
            :to="{ name: 'RooofAccountEdit', params: { id: scope.row.id } }"
            type="primary"
          >
            {{ scope.row.email }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Phone"
        prop="phone"
        width="200"
        sortable
      />
      <el-table-column
        label="Title"
        prop="title"
        width="200"
        sortable
      />
      <el-table-column
        label="Active"
        prop="is_active"
        width="130"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.is_active ? 'Yes' : 'No' }}
        </template>
      </el-table-column>
      <el-table-column
        label="Companies"
        min-width="250"
      >
        <template slot-scope="scope">
          <div
            v-for="name in getCompanyAssociations(scope.row)"
            :key="name"
          >
            <link-button
              :to="getCompanyLink(name)"
              type="primary"
            >
              {{ name }}
            </link-button>
          </div>
        </template>
      </el-table-column>
    </async-paged-table>
  </div>
</template>

<script>
import RooofAccountFiltersDialog from './RooofAccountFiltersDialog'
import AsyncPagedTable from '@/components/tables/AsyncPagedTable'
import RooofAccountAPI from '@/services/api/accounts'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  name: 'RooofAccountTable',
  components: {
    'async-paged-table': AsyncPagedTable,
    'link-button': LinkButton,
    'filters-dialog': RooofAccountFiltersDialog
  },
  data () {
    return {
      data: [],
      filters: {
        is_active: null,
        is_staff: null,
        is_superuser: null,
        is_poster: null,
        is_corporate: null,
        decision_maker: null,
        emergency_contact: null,
        company: '',
        property: ''
      },
      total: 0,
      loading: false,
      showFiltersDialog: false
    }
  },
  computed: {
    /**
     * Count of active filters
     */
    filterCount () {
      let count = 0
      for (const key in this.filters) {
        const value = this.filters[key]
        if (value !== null && value !== '') {
          count++
        }
      }
      return count
    },
    /**
     * Remove all falsy values from this.filters.
     *
     * @returns {Object}
     */
    cleanedFilters () {
      const filters = {}
      for (const key in this.filters) {
        const value = this.filters[key]
        if (value !== null && value !== '') {
          filters[key] = value
        }
      }
      return filters
    }
  },
  created () {
    // restore filters from route query
    const query = this.$route.query
    const keys = Object.keys(query)
    for (const key of keys) {
      if (key === 'page_size' || key === 'page' || key === 'search') {
        continue
      }

      if (query[key] === 'true') {
        this.filters[key] = true
      } else if (query[key] === 'false') {
        this.filters[key] = false
      } else {
        this.filters[key] = query[key]
      }
    }
  },
  methods: {
    /**
     * Fetch a single page of rooof accounts from the api.
     *
     * @param {Object} params - query params (page_size, page, search)
     */
    async fetchData (params) {
      this.loading = true
      try {
        const query = { ...params, ...this.cleanedFilters }
        this.$emit('filter-update', query)

        // save search filters as route query
        this.$router.replace({ path: this.$route.path, query: query }).catch(err => err)

        const response = await RooofAccountAPI.users.listPage(query)
        this.data = response.results
        this.total = response.count
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loading = false
    },
    /**
     * onChange handler for table filters.
     *
     * @param {String} filter - target filter
     * @param {String|Boolean} value - selected value
     */
    handleFilterUpdate (filter, value) {
      this.filters[filter] = value
    },
    /**
     * Handler for when the filters dialog is submitted
     */
    handleApplyFilters () {
      this.showFiltersDialog = false
      this.$refs.table.performSearch()
    },
    /**
     * Clears all filters excluding search
     */
    resetFilters () {
      this.filters = {
        is_active: null,
        is_staff: null,
        is_superuser: null,
        is_poster: null,
        is_corporate: null,
        decision_maker: null,
        emergency_contact: null,
        company: '',
        property: ''
      }
      this.$refs.table.performSearch()
    },
    /**
     * Returns link to company overview page
     *
     * @param {String} name
     * @returns {Object}
     */
    getCompanyLink (name) {
      const company = this.$store.getters.getCompanyByName(name)
      return company ? { name: 'CompanyOverview', params: { cid: company.id } } : { name: 'Root' }
    },
    /**
     * Returns an account's company associations based on
     * their postengine_groups and invoicing_groups
     *
     * @param {Object} account - Rooof Account
     * @returns {Array} - Company names
     */
    getCompanyAssociations (account) {
      const companies = new Set()
      for (const reportingGroup of account.postengine_groups) {
        companies.add(reportingGroup.group.company.human_name)
      }
      for (const invoicingGroup of account.invoicing_groups) {
        companies.add(invoicingGroup.group.company.human_name)
      }
      return Array.from(companies).sort()
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-btn {
  margin-left: 10px;
}
</style>
