<template>
  <div id="data-export-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      title="Export Data"
      width="40%"
      @close="close"
    >
      <div v-loading="exporting">
        Select the format to export the data in:
        <ul>
          <li v-for="format in formats" :key="format.value">
            <el-button type="text" @click="exportData(format.value)">
              {{ format.label }}
            </el-button>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Papa from 'papaparse'
import XLSX from 'xlsx'

import { flattenObject, downloadFile } from '@/utils'

export default {
  name: 'DataExportDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    dataType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      exporting: false,
      formats: [
        { label: 'CSV', value: 'csv' },
        { label: 'JSON', value: 'json' },
        { label: 'XLSX', value: 'xlsx' }
      ]
    }
  },
  methods: {
    /**
     * Download a file with the exported data.
     *
     * @param {String} format - export format/file extension
     */
    exportData (format) {
      this.exporting = true
      let data = []
      let mimeType = ''

      switch (format) {
        case 'csv':
          data = this.getCSVData()
          mimeType = 'text/csv'
          break
        case 'json':
          data = this.getJSONData()
          mimeType = 'application/json'
          break
        case 'xlsx':
          data = this.getXLSXData()
          mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
      }

      const date = this.$moment().format('YYYYMMDD')
      const fileName = `rooof-${this.dataType}-${date}`

      if (format === 'xlsx') {
        XLSX.writeFile(data, `${fileName}.${format}`)
      } else {
        downloadFile(data, fileName, format, mimeType)
      }

      this.exporting = false
      this.close()
    },
    /**
     * Flatten each object in `data` so that it can be
     * represented on a spreadsheet.
     *
     * @returns {Array}
     */
    formatData () {
      const data = []
      for (const row of this.data) {
        const rowCopy = JSON.parse(JSON.stringify(row))
        data.push(flattenObject(rowCopy))
      }
      return data
    },
    /**
     * Format data as csv.
     *
     * @returns {Array}
     */
    getCSVData () {
      const data = this.formatData()
      const config = {
        header: true,
        skipEmptyLines: true
      }
      const csv = Papa.unparse(data, config)

      return csv
    },
    /**
     * Format data as json.
     *
     * @returns {Array}
     */
    getJSONData () {
      return JSON.stringify(this.data, null, 2)
    },
    /**
     * Format data as xlsx.
     *
     * @returns {Array}
     */
    getXLSXData () {
      const data = this.formatData()
      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet)

      return workbook
    },
    /**
     * Close event to be handled by the parent.
     */
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.el-button {
  padding: 5px 0;
}
</style>
